<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- 媒体登録ダイアログ -->
      <shop-site-dialog-component
        ref="shopSiteDialog"
        @save="save"
        @refresh="refresh"
      ></shop-site-dialog-component>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="shop_sites"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon
            class="pa-1"
            @click="editItem(item)"
            :disabled="
              item.delete_date < utilDate.getDateFormatLong(new Date()) ||
              item.is_active == false
            "
          >
            mdi-pencil
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.sites_name="{ item }">
          <span
            :style="item.color.length != 0 ? '; color:' + item.color : ''"
            class="text-align-middle"
            >{{ item.sites_name }}</span
          >
        </template>
        <!-- eslint-disable-next-line -->
        <!-- <template v-slot:item.loginid="{ item }">
          <span v-if="!item.sns_link_flg">{{ item.loginid }}</span>
          <div v-else class="d-inline-flex align-center">
            <v-avatar size="36px">
              <img alt="Avatar" :src="item.sns_user_icon" />
            </v-avatar>
            <div class="ml-2">
              {{ item.sns_user_name }}<br />@{{ item.loginid }}
            </div>
          </div>
        </template> -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.manage="{ item }">
          <a
            target="_blank"
            :href="item.auth_url"
            style="text-decoration: none"
          >
            <v-icon :color="item.color">mdi-application-cog-outline</v-icon>
          </a>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete_date="{ item }">
          <span v-if="item.delete_date">
            <span
              v-if="
                item.delete_date < utilDate.getDateFormatLong(new Date()) ||
                item.is_active == false
              "
              >{{ item.delete_date }}</span
            >
            <span v-else
              ><a @click="setDeleteDate(item)">{{ item.delete_date }}</a></span
            >
          </span>
          <span v-else><a @click="setDeleteDate(item)">設定する</a></span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <span
            v-if="
              item.delete_date < utilDate.getDateFormatLong(new Date()) ||
              item.is_active == false
            "
          >
            <v-icon class="pa-1" @click="deleteRestoreItem(item)">
              mdi-delete-restore
            </v-icon>
          </span>
          <span v-else>
            <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
          </span>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>

    <!-- 削除日設定ダイアログ -->
    <v-dialog v-model="deleteDateDialog" scrollable persistent width="400">
      <v-card :disabled="loginCheckLoading">
        <v-card-title>
          <span class="text-h5">使用終了日の設定</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <ValidationObserver ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="使用終了日"
                    rules=""
                  >
                    <v-text-field
                      ref="delete_date"
                      v-model="dialogDeleteDate"
                      name="delete_date"
                      :error-messages="errors"
                      :success="valid"
                      type="date"
                      label="使用終了日"
                      hint="媒体の使用を停止する日を設定してください。停止を取り消す場合は空白で保存します"
                      persistent-hint
                      outlined
                      clearable
                      :min="utilDate.getDateFormatLong(new Date())"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="6">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDeleteDateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="6">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="saveDeleteDateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import ShopSiteDialogComponent from "./shopSiteDialog.vue";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  components: {
    ShopSiteDialogComponent,
  },
  props: ["shop_id", "shop_sites"],
  setup(props, ctx) {
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const shopSiteDialog = ref();
    const state = reactive({
      deleteDateDialog: false, // ダイアログ表示用
      dialogDeleteDate: "", // 削除日
      search: "",
      sites: [], // 媒体リスト
      dialog: false, // ダイアログ表示用
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "媒体名",
          value: "sites_name",
          sortable: false,
          class: "td_sites_name",
        },
        { text: "メモ", value: "memo", sortable: false, class: "td_memo" },
        {
          text: "管理画面",
          value: "manage",
          sortable: false,
        },
        {
          text: "ID",
          value: "loginid",
          sortable: true,
          class: "td_loginid",
        },
        {
          text: "パスワード",
          value: "password",
          sortable: true,
          class: "td_password",
        },
        {
          text: "個別サーバ",
          value: "proxy_server",
          sortable: false,
        },
        {
          text: "タスク",
          value: "task_server_id",
          sortable: false,
        },
        {
          text: "開始日",
          value: "insert_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "終了日",
          value: "delete_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      item: {
        id: 0,
        site_id: 0,
        default_site_id: 0,
        loginid: "",
        password: "",
        auth_url: "",
        memo: "",
        color: "",
        mask: "",
        menu: false,
        sites_category: "",
        // sns_user_name: "",
        // sns_user_icon: "",
        // sns_link_flg: false,
      },
      loginCheckLoading: false,
      loginCheckButtonText: "ログイン確認",
      mask: "!#XXXXXXXX",
      menu: false,
      color_num: setting.shop_site_colors.length,
    });

    // 編集
    const editItem = (item) => {
      const index = props.shop_sites.indexOf(item);
      const editItems = {
        id: props.shop_sites[index].id,
        shop_id: props.shop_sites[index].shop_id,
        site_id: props.shop_sites[index].site_id,
        loginid: props.shop_sites[index].loginid,
        password: props.shop_sites[index].password,
        auth_url: props.shop_sites[index].auth_url,
        memo: props.shop_sites[index].memo,
        color: props.shop_sites[index].color,
        proxy_server: props.shop_sites[index].proxy_server,
        proxy_server_label: props.shop_sites[index].proxy_server_label,
        delete_date: props.shop_sites[index].delete_date,
        category: props.shop_sites[index].sites_category,
        // sns_user_name: props.shop_sites[index].sns_user_name,
        // sns_user_icon: props.shop_sites[index].sns_user_icon,
        // sns_link_flg: props.shop_sites[index].sns_link_flg,
      };
      // // ダイアログ開く
      shopSiteDialog.value.showDialog(editItems);
    };

    // データ削除
    const deleteItem = async (item) => {
      if (
        window.confirm(
          "削除してよろしいですか？\n更新中のタイマーはすべて停止します"
        )
      ) {
        // 削除処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        // 更新
        const index = props.shop_sites.indexOf(item);
        // データ削除処理(is_activeをfalseに)
        await ShopSitesRepository.admin_delete(props.shop_sites[index].id)
          .then((response) => {
            // 更新OK
          })
          .catch((error) => {
            throw "ERROR:ShopSitesRepository.admin_delete (" + error + ")";
          });

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
        ctx.emit("reload");
      }
    };

    // サイトが新規追加されたとき
    const save = () => {
      // ctx.emit("reload");
      ctx.emit("tabmove", 0);
    };

    const refresh = async () => {
      ctx.emit("tabmove", 0);
    };

    // データ復元
    const deleteRestoreItem = async (item) => {
      if (!window.confirm("データを復元しますか？")) {
        return;
      }

      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 更新
      const params = {
        id: item.id,
        shop_id: item.shop_id,
        site_id: item.site_id,
        loginid: item.loginid,
        password: item.password,
        auth_url: item.auth_url,
        delete_date: "",
        is_active: true,
      };
      // データ更新処理
      await ShopSitesRepository.admin_update(params)
        .then((response) => {
          if (response.data) {
            // 更新OK
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitesRepository.admin_update (" + error + ")";
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      ctx.emit("reload");
    };

    const cancelDeleteDateDialog = () => {
      state.deleteDateDialog = false;
    };

    const saveDeleteDateDialog = async () => {
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
      } else {
        if (!window.confirm("削除予定日を設定します。\nよろしいですか？")) {
          return;
        }

        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // 更新
        const params = {
          id: state.item.id,
          shop_id: state.item.shop_id,
          site_id: state.item.site_id,
          loginid: state.item.loginid,
          password: state.item.password,
          auth_url: state.item.auth_url,
          delete_date: state.dialogDeleteDate,
        };
        // データ更新処理
        await ShopSitesRepository.admin_update(params)
          .then((response) => {
            if (response.data) {
              // 更新OK
              ctx.refs.observer.reset(); // 入力エラー表示クリア
            }
          })
          .catch((error) => {
            throw "ERROR:ShopSitesRepository.admin_update (" + error + ")";
          });

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示

        const index = props.shop_sites.indexOf(state.item);
        props.shop_sites[index].delete_date = state.dialogDeleteDate;
        state.deleteDateDialog = false;
      }
    };

    // 削除日設定
    const setDeleteDate = async (item) => {
      state.item = item;
      state.dialogDeleteDate = item.delete_date ? item.delete_date : "";
      state.deleteDateDialog = true;
    };

    // 返却オブジェクト定義
    return {
      props,
      setting,
      utilDate,
      shopSiteDialog,
      ...toRefs(state),
      editItem,
      deleteItem,
      deleteRestoreItem,
      save,
      setDeleteDate,
      cancelDeleteDateDialog,
      saveDeleteDateDialog,
      refresh,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}

::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_sites_name {
  min-width: 150px;
}
::v-deep .td_memo {
  min-width: 150px;
}
::v-deep .td_date {
  min-width: 90px;
  width: 90px;
}
</style>
